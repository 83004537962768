import React, { useState } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons";

function App() {
  const [email, setEmail] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Subscribing email: ${email}`);

    try {
      fetch("http://localhost:3001/createOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (e) {
      console.log(e);
    }

    setEmail("");
  };

  return (
    <div className="coming-soon-container">
      <div className="logo-container">
        <div className="overlay-text">
          <div className="coming-soon-text">COMING SOON</div>
          <p className="subscription-text">
            Yield redefined: earn more, risk less with Sage Finance.
          </p>

          <div className="notifed-text">
            <p>Get notified.</p>
          </div>
          <form className="subscription-form" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email address"
              name="entry.57585097"
              value={email}
              style={{ border: "1px solid grey" }}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" style={{ backgroundColor: "#252525" }}>
              Sign up
            </button>
          </form>

          <div
            className="footer-container"
            style={{ display: "flex", gap: 25, marginTop: "5%" }}
          >
            <a
              href="https://twitter.com/Sage_Fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} size="xl" color="grey" />
            </a>
            <a
              href="https://discord.gg/zNSmMePEKK"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} size="xl" color="grey" />
            </a>
          </div>
        </div>

        <div className="mobile">
          <img
            className="logo2"
            src="/sage-coinflip-green.webp"
            alt="Description"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
